import "./Header.scss";
import { AnimatePresence, motion } from "framer-motion";
import { AppWrap } from "../../wrapper";
import { images } from "../../constants";

const scaleVariants = {
	whileInView: {
		scale: [0, 1],
		opacity: [0, 1],
		transition: {
			duration: 1,
			ease: "easeInOut",
		},
	},
};

const Header = () => {
	return (
		<div className="app__header app__flex">
			<AnimatePresence mode="wait">
				<motion.div
					whileInView={{ x: [-100, 0], opacity: [0, 1] }}
					transition={{ duration: 0.5 }}
					className="app__header-info"
					key="header-info"
				>
					<div className="app__header-badge">
						<div className="badge-cmp app__flex">
							<span>👋</span>
							<div style={{ marginLeft: 20 }}>
								<p className="p-text">Hello, I'm</p>
								<h1 className="head-text">Corey Floyd</h1>
							</div>
						</div>
						<div className="tag-cmp app__flex">
							<p className="p-text">I am a full stack</p>
							<p className="p-text">Software Developer</p>
						</div>
					</div>
				</motion.div>

				<div className="app_header-img-wrapper" key="header-image">
					<motion.div
						whileInView={{ opacity: [0, 1] }}
						transition={{ duration: 0.5, delayChildren: 0.5 }}
						className="app__header-img"
					>
						<img src={images.profile} alt="profile_bg" />
						<motion.img
							whileInView={{ scale: [0, 1] }}
							transition={{ duration: 1, ease: "easeInOut" }}
							src={images.circle}
							alt="profile_circle"
							className="overlay_circle"
						/>
					</motion.div>
				</div>
				<motion.div
					variants={scaleVariants}
					whileInView={scaleVariants.whileInView}
					className="app__header-circles"
					key="header-circles"
				>
					{[images.react, images.csharp, images.java].map((circle, idx) => (
						<div className="circle-cmp app__flex" key={`circle-${idx}`}>
							<img src={circle} alt="circle" />
						</div>
					))}
				</motion.div>
			</AnimatePresence>
		</div>
	);
};
export default AppWrap(Header, "home");
// export default Header;
