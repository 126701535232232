import { AnimatePresence, motion } from "framer-motion";

const MotionWrap = (Component, classNames) =>
	function HOC() {
		return (
			<AnimatePresence mode="wait">
				<motion.div
					whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
					transition={{ duration: 0.5 }}
					className={`${classNames} app__flex`}
				>
					<Component />
				</motion.div>
			</AnimatePresence>
		);
	};
export default MotionWrap;
