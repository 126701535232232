import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

console.log(process.env.REACT_SANITY_PROJECT_ID);
export const client = sanityClient({
	projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
	dataset: "production",
	apiVersion: "2022-08-01",
	useCdn: true,
	token: process.env.REACT_APP_SANITY_TOKEN,
});

export const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
