import React from "react";

import { About, Footer, Header, Skills } from "./container";
import { Navbar } from "./components";
import { Helmet, HelmetProvider } from "react-helmet-async";

import "./App.scss";

const App = () => {
	return (
		<HelmetProvider>
			<Helmet>
				<title>Corey-Floyd.com</title>
				<meta name="description" content="Corey Floyd's personal portfolio" />
				<meta
					name="keywords"
					content="Corey Floyd, Corey, Floyd, Portfolio, Developer, Web Developer, Software Engineer"
				/>
				<meta name="og:type" content="website" />
				{/* <meta name="og:title" content="Corey-Floyd.com" /> */}
				<meta
					name="og:description"
					content="Corey Floyd's personal portfolio"
				/>
				<meta name="og:url" content="https://www.corey-floyd.com" />
				{/* <meta name="og:image" content="https://www.corey-floyd.com/logo.png" /> */}
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content="Corey-Floyd.com" />
				<meta
					name="twitter:description"
					content="Corey Floyd's personal portfolio"
				/>
				<meta
					name="twitter:image"
					content="https://www.corey-floyd.com/logo.png"
				/>
			</Helmet>
			<div className="app">
				<Navbar />
				<Header />
				<About />
				{/* <Work /> */}
				<Skills />
				{/* <Testimonial /> */}
				<Footer />
			</div>
		</HelmetProvider>
	);
};

export default App;
