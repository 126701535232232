import { BsLinkedin } from "react-icons/bs";
// import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const SocialMedia = () => {
	return (
		<div className="app__social">
			{/* <div>
				<BsTwitter />
			</div> */}
			<div>
				<a href="https://www.linkedin.com/in/coreyrfloyd">
					<BsLinkedin />
				</a>
			</div>
			{/* <div>
				<FaFacebookF />
			</div> */}
		</div>
	);
};
export default SocialMedia;
