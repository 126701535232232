import "./Skills.scss";
import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { AnimatePresence, motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import { urlFor, client } from "../../client";

const Skills = () => {
	const [skills, setSkills] = useState([]);
	const [experiences, setExperiences] = useState([]);
	const [showTooltip, setShowTooltip] = useState(true);
	const [tooltipContent, setTooltipContent] = useState("");

	useEffect(() => {
		const experienceQuery = "*[_type == 'experiences']";
		const skillsQuery = "*[_type == 'skills']";

		client.fetch(experienceQuery).then((result) => {
			console.log(result);
			const sorted = result.sort((a, b) => {
				const yearA = a.year.split("-")[0];
				const yearB = b.year.split("-")[0];
				return +yearA > +yearB ? -1 : 1;
			});

			setExperiences(sorted);
		});
		client.fetch(skillsQuery).then((result) => {
			setSkills(result);
		});
	}, []);

	return (
		<>
			<h2 className="head-text">Skills & Experiences</h2>

			<div className="app__skills-container">
				<AnimatePresence mode="wait">
					<motion.div className="app__skills-list">
						{skills.map((skill) => (
							<motion.div
								whileInView={{ opacity: [0, 1] }}
								transition={{ duration: 0.5 }}
								className="app__skills-item app__flex"
								key={skill.name}
							>
								<div
									className="app__flex"
									style={{ backgroundColor: skill.bgColor }}
								>
									<img src={urlFor(skill.icon)} alt={skill.name} />
								</div>
								<p className="p-text">{skill.name}</p>
							</motion.div>
						))}
					</motion.div>
				</AnimatePresence>
				<div className="app__skills-exp">
					{experiences.map((experience) => (
						<AnimatePresence mode="wait">
							<motion.div
								className="app__skills-exp-item"
								key={experience.year}
							>
								<div className="app__skills-exp-year">
									<p className="bold-text">{experience.year}</p>
								</div>
								<motion.div className="app__skills-exp-works">
									{experience.works.map((work) => (
										<>
											<motion.div
												whileInView={{ opacity: [0, 1] }}
												transition={{ duration: 0.5 }}
												className="app__skills-exp-work"
												data-tip
												data-for={work.name}
												key={work.name}
												onMouseEnter={() => {
													setTooltipContent(work.desc);
													setShowTooltip(true);
													console.log(work.desc);
												}}
												onMouseLeave={() => {
													setShowTooltip(false);
													setTooltipContent(null);
													setTimeout(() => setShowTooltip(true), 50);
												}}
											>
												<h4 className="bold-text">{work.name}</h4>
												<p className="p-text">{work.company}</p>
											</motion.div>
											{showTooltip && (
												<ReactTooltip
													id={work.name}
													effect="solid"
													arrowColor="#fff"
													className="skills-tooltip"
												>
													{tooltipContent}
												</ReactTooltip>
											)}
										</>
									))}
								</motion.div>
							</motion.div>
						</AnimatePresence>
					))}
				</div>
			</div>
		</>
	);
};

export default AppWrap(
	MotionWrap(Skills, "app__skills"),
	"skills",
	"app__whitebg"
);
