import "./About.scss";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";

import { images } from "../../constants";

import { client, urlFor } from "../../client";

const About = () => {
	const [abouts, setAbouts] = useState([]);

	useEffect(() => {
		const query = '*[_type == "abouts"]';
		client.fetch(query).then((data) => setAbouts(data));
	}, []);

	return (
		<>
			<h2 className="head-text">
				I Know That <span>Good Software</span>
				<br />
				Starts With <span>Good Design</span>
			</h2>

			<div className="app__profiles">
				{abouts
					.sort((a) => a.description)
					.map((about, index) => (
						<AnimatePresence mode="wait" key={about.title + index}>
							<motion.div
								whileInView={{ opacity: 1.1 }}
								whileHover={{ scale: 1.1 }}
								transition={{ duration: 0.5, transition: "tween" }}
								className="app__profile-item"
								key={about.title + index}
							>
								<img src={urlFor(about.imgUrl)} alt={about.title}></img>
								<h2 className="bold-text" style={{ marginTop: 20 }}>
									{about.title}
								</h2>
								<p className="p-text" style={{ marginTop: 10 }}>
									{about.description}
								</p>
							</motion.div>
						</AnimatePresence>
					))}
			</div>
		</>
	);
};
export default AppWrap(
	MotionWrap(About, "app__about"),
	"about",
	"app__whitebg"
);
